<template>
   
    <div class="row">
       <div class="col-md-12">
           <button class="btn btn-primary bg-aqua-active btn-info margin-r-5" v-tooltip="{text: 'Send new agent email to John Doe',theme: {placement: 'bottom', },displayArrow : true }" v-on:click="show('agentemailcontent')">
               <i class="fa fa-envelope"></i>
               Agent email
           </button> 
           <button class="btn  btn-success margin-r-5" v-tooltip="{text: 'Add note to John Doe',theme: {placement: 'bottom', }}"  v-on:click="show('createnotecontent')">
           <i class="fa fa-pencil-square-o"></i>
           Note
           </button>
           <button class="btn  btn-warning margin-r-5" v-tooltip="{text: 'Create Ticket for John Doe',theme: {placement: 'bottom', }}"  v-on:click="show('createticketcontent')">
           <i class="fa fa-ticket"></i>
           Ticket
           </button>
           <button class="btn  btn-purple margin-r-5" v-tooltip="{text: 'Forward a copy of this via email',theme: {placement: 'bottom', }}" v-on:click="show('forwardccopyontent')">
           <i class="fa fa-mail-forward"></i>
           Forward Copy
           </button>
           <div class="btn-group pull-right dropdown" auto-close="outsideClick">
               <button type="button" class="btn btn-default" v-on:click = "toggleMore = !toggleMore" v-tooltip="{text: 'more',theme: {placement: 'bottom', }}">
                 <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
               </button>
               <ul class="dropdown-menu dropdown-menu-right dropdown-bg dropdown-more" v-bind:class = "toggleMore?'':'show'">
                   <li role="menuitem" ng-class="{ 'bg-primary': isStatusAuditSectionExpanded }">
                      
                           <label class="switch">
                               <Toggle v-model="statusaudit" />
                               Show Status Audit
                           </label>
                   </li>
                   <li role="menuitem">
                       
                           <label class="switch">
                               <Toggle v-model="fullcasehistory" />
                               Show Full Case
                           </label>
                      
                   </li>
                   <li role="menuitem">
                           <label class="switch">
                               <Toggle v-model="palintext" />
                               Display Plain Text
                           </label>
                   </li>
                   <li role="menuitem"><a href="#"><i class="fa fa-print"></i> Print</a></li>
                   <li role="menuitem" class="text-red">
                       <a href="unsafe:javascript:void(0)" class="text-red" v-on:click="showconfirmdelete  = true;" v-show="!showconfirmdelete" style=""><i class="fa  fa-trash"></i> Delete This Interaction</a>
                       <div v-show="showconfirmdelete" class="ng-hide" style="">
                           <p class="text-red">Do you really want to delete this interaction?</p>
                           <div>
                               <button type="button" class="btn btn-danger btn-sm" v-on:click="cancel('getthis');toggleRow('row1'); showDeleteSuccess = true;">Confirm Deletion</button>
                               <button type="button" class="btn btn-link" v-on:click="showconfirmdelete = false">Cancel</button>
                           </div>
                       </div>
                   </li>
               </ul>
           </div>
   
           <button v-if = " showget "  class="btn  btn-info margin-r-5 pull-right" v-tooltip="{text: 'Assign this to me',theme: {placement: 'bottom', }}" v-on:click="show('getthiscontent')">
             <i class="fa fa-sign-in"></i>
              Get This
           </button>
          
       </div>
       <StatusAudit v-if = 'statusaudit'></StatusAudit>
       <FullCaseHistory v-if = "fullcasehistory"></FullCaseHistory>
       <div class="col-md-12">    
           <div class="nav-tabs-custom mtop-15">
               <ul class="nav nav-tabs" id="myTab" role="tablist">
   
                   <li class="nav-item" role="presentation">
                       <a class="nav-link active" v-bind:id="'tab2'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_2_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'This interaction has file attachments.',theme: {placement: 'bottom', }}" ><i class="fa fa-paperclip"></i> <span>Details & Attachments</span><span class="new-inside"></span></a>
                   </li>
                   <li class="nav-item" role="presentation" v-if="!showPaymentStatus">
                       <a class="nav-link" v-bind:id="'tab3'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_3_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'There are property values set for this interactions.',theme: {placement: 'bottom', }}"><i class="fa fa-fw fa-exchange"></i> <span>Interaction Properties</span><span class="new-inside"></span></a>
                   </li>
                   
                   <li class="nav-item" role="presentation" v-if="!showPaymentStatus">
                       <a class="nav-link" v-bind:id="'tab4'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_4_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'There is private comments for this interactions.',theme: {placement: 'bottom', }}"><i class="fa fa-fw fa-pencil-square-o"></i> <span>Private Comment</span><span class="new-inside"></span></a>
                   </li>
   
               </ul>
               <div class="tab-content" id="myTabContent">
               
                   <div class="tab-pane show active" v-bind:id="'tab_2_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                      <CalendarDetailsandattachmentsTabContent></CalendarDetailsandattachmentsTabContent>
                   </div>
   
                   <div class="tab-pane fade" v-bind:id="'tab_3_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                       <InteractionPropertyTab></InteractionPropertyTab>
                   </div>
               
                   <div class="tab-pane fade" v-bind:id="'tab_4_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                   <div class="row form-horizontal">
                       <div class="col-md-12">
                           Please show me the other ranges in Plasma tv.
                       </div>  
                       </div>
                   </div>
               </div>
           </div>
       </div>
       <div class="col-md-12">
           <div class="message-box overflow-box">
           <div class="message-box-body">
               <div class="clear"></div>
               
               <div class="col-md-6">
                <div style="word-break: break-word; overflow-wrap: anywhere;">
                <p><strong><span style="text-decoration: underline">Request for Estimate</span><br><br></strong></p>
                <p><strong>Name: </strong>Jacob<strong> </strong>Smith<br><strong>Mobile Phone #:</strong> (512)-605-9963<br><strong>Email Address:</strong> JacobSmith8@xyz.net<br><br><strong>Property Address:</strong> Glen Canyon Dr, Round Rock, TX 78681<br><br><strong>Description of Project:</strong> need new insulation in my attic<br><br><span></span></p>
                </div>
                </div>
             
           </div>
           </div>
       </div>
   </div>
   </template>
   <style src="@vueform/toggle/themes/default.css"></style>
   <script>
   import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
   import CalendarDetailsandattachmentsTabContent from '../components/CalendarDetailsandattachmentsTabContent.vue';
   
   
   import StatusAudit from '../components/StatusAudit.vue';
   import FullCaseHistory from '../components/FullCaseHistory.vue';
   import Toggle from '@vueform/toggle'
   
   import Multiselect from '@vueform/multiselect'
   
       export default {
         name: 'Expanded Row Content',
         props: {showGetThis:Boolean,showPaymentStatus:Boolean,showAnswer:Boolean},
         components: {
           Multiselect,
           InteractionPropertyTab,
           CalendarDetailsandattachmentsTabContent,
           StatusAudit,
           FullCaseHistory,
           Toggle
           
         },
         mounted(){
              if(this.showGetThis == 0){
                  this.showget = false;
              }else{
                 this.showget = true;
              }
   
               if(this.showAnswer == 0){
                  this.showanswer = false;
              }else{
                 this.showanswer = true;
              }
           },
         data: function(){
             return{
               showBCC : true,
               date: new Date(),
               timezone: '',
               showexpanded1: false,
               toggleMore : true,
               statusaudit : false,
               fullcasehistory : false,
               palintext: true,
               showget : Boolean(this.showGetThis),
               showanswer : Boolean(this.showGetThis),
               showconfirmdelete:false
             }
         },
          methods: {
               show(segment){
                 this.emitter.emit("show-view-details",{displaySegment:segment});
               }
               
           }
       }
   </script>
   
   