<template>
    <div class="row">
        <div class="col-md-12">
            <div class="box">
                <div class="box-header">
                   <h1 class="box-title pull-right mright-15" v-show="showCalendar"> <span class="pull-right calendar-toggle" v-on:click="toggleCalendar()"><i class="fa fa-list"></i></span></h1>
                   <h1 class="box-title pull-right mright-15" v-show="!showCalendar"> <span class="pull-right calendar-toggle" v-on:click="toggleCalendar()"><i class="fa fa-calendar"></i></span></h1>
                </div>
                <div class="box-body">
                    <div v-show="showCalendar">
                        <ScheduleXCalendar
                            :calendar-app="calendarApp"
                            :custom-components="customComponents"
                        >
                        </ScheduleXCalendar>
                    </div>
                    <div v-show="!showCalendar">
                        <CalendarMessageTableList> </CalendarMessageTableList>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
 </template>
 <style src="@vueform/toggle/themes/default.css"></style>
 <script>
     import * as Vue from 'vue'
     import Toggle from '@vueform/toggle'
     import Multiselect from '@vueform/multiselect'
     import iServiceSwitch from '../components/iServiceSwitch.vue';
     import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
     import MyEstimateEmailReminderPreview from '../components/MyEstimateEmailReminderPreview.vue';
     import EstimateQuotePreview from '../components/EstimateQuotePreview.vue';
     import MyEstimateEstimater from '../components/MyEstimateEstimater.vue';
     import EmailVisitNotice from '../components/EmailVisitNotice.vue';
     import { ScheduleXCalendar } from '@schedule-x/vue'
     import CalendarMessageTableList from '../components/CalendarMessageTableList.vue';
     
     var iservice = window.iservice || {};

     iservice.date = {};
  ((date) => {
    function isValid(date) {
      if (!date) return false;
      if (!(date instanceof Date)) return false;
      return date.toString() != 'Invalid Date';
    }
    function makeDate(obj) {
      if (obj instanceof Date) return obj;
      if (typeof obj == 'string' || obj instanceof String) {
        return new Date(obj);
      }
      return null;
    }
    function utcYYYY(date) { return `${date.getUTCFullYear()}`; }
    function utcMM(date) { var month = `${date.getUTCMonth() + 1}`; return month.length < 2 ? `0${month}` : month; }
    function utcDD(date) { var day = `${date.getUTCDate()}`; return day.length < 2 ? `0${day}` : day; }
    function localYYYY(date) { return `${date.getFullYear()}`; }
    function localMM(date) { var month = `${date.getMonth() + 1}`; return month.length < 2 ? `0${month}` : month; }
    function localM(date) { return `${date.getMonth() + 1}`; }
    function localDD(date) { var day = `${date.getDate()}`; return day.length < 2 ? `0${day}` : day; }
    function localHH(date) { var hours = `${date.getHours()}`; return hours.length < 2 ? `0${hours}` : hours; }
    function localmm(date) { var minute = `${date.getMinutes()}`; return minute.length < 2 ? `0${minute}` : minute; }
    function localss(date) { var seconds = `${date.getSeconds()}`; return seconds.length < 2 ? `0${seconds}` : seconds; }
    function localms(date) { var ms = `${dateObj.getMilliseconds()}`; return ms.length < 2 ? `00${ms}` : (ms.length < 3 ? `0${ms}` : ms); }

    date.apiDateIn = function (utcStr) {
      var utc = new Date(utcStr);
      if (!isValid(utc)) return null;
      var local = new Date();
      local.setFullYear(utc.getUTCFullYear(), utc.getUTCMonth(), utc.getUTCDate());
      return local;
    }
    date.apiDateOut = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${iservice.date.localYYYYMMDD(date)}T00:00:00.000Z`
    }
    date.apiDateExport = function (utcStr) { //export to excel. directly output the utc date sent from api.
      var utc = new Date(utcStr);
      if (!isValid(utc)) return null;
      return utc;
    }
    date.apiDatetimeIn = function (utcStr) {
      var utc = new Date(utcStr);
      if (!isValid(utc)) return null;
      return utc;
    }
    date.apiDatetimeOut = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return date.toISOString();
    }
    date.apiDatetimeExport = function (utcStr) { //export to excel. take utc from api, get local values and store in utc time zone.
      var local = new Date(utcStr);
      if (!isValid(local)) return null;
      var utc = new Date();
      utc.setUTCFullYear(local.getFullYear(), local.getMonth(), local.getDate());
      utc.setUTCHours(local.getHours(), local.getMinutes(), local.getSeconds(), local.getMilliseconds());
      return utc;
    }
    date.utcYYYYMMDD = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${utcYYYY(date)}-${utcMM(date)}-${utcDD(date)}`;
    }
    date.localMDD = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${localM(date)}/${localDD(date)}`;
    }
    date.localYYYYMMDD = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${localYYYY(date)}-${localMM(date)}-${localDD(date)}`;
    }
    date.localYYYYMMDDHmm = function (date, minute) {
      if (!isValid(date = makeDate(date))) return '';
      if (minute) date.setMinutes(date.getMinutes() + minute);
      var hours = date.getHours();
      hours = hours < 10 ? `0${hours}` : `${hours}`;
      var minutes = date.getMinutes();
      minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      return `${localYYYY(date)}-${localMM(date)}-${localDD(date)} ${hours}:${minutes}`;
    }
    date.localYYYYMMDDHmmA = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      var hours = date.getHours();
      var hour = hours == 0 ? '12' : (hours < 13 ? `${hours}` : `${hours - 12}`);
      var ampm = hours < 12 ? 'AM' : 'PM';
      return `${localYYYY(date)}-${localMM(date)}-${localDD(date)} ${hour}:${localmm(date)} ${ampm}`;
    }
    date.localYYYYMMDDHmmssA = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      var hours = date.getHours();
      var hour = hours == 0 ? '12' : (hours < 13 ? `${hours}` : `${hours - 12}`);
      var ampm = hours < 12 ? 'AM' : 'PM';
      return `${localYYYY(date)}-${localMM(date)}-${localDD(date)} ${hour}:${localmm(date)}:${localss(date)} ${ampm}`;
    }
    date.localYYYY = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${localYYYY(date)}`;
    }
    date.localHmmA = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      var hours = date.getHours();
      var hour = hours == 0 ? '12' : (hours < 13 ? `${hours}` : `${hours - 12}`);
      var ampm = hours < 12 ? 'AM' : 'PM';
      return `${hour}:${localmm(date)} ${ampm}`;
    }
  })(iservice.date);
  var feature = Vue.reactive({ isCalendar: true, preFixId: "", postFixId: "", selectedDate: "", events: [], errors: [], sucess: null, calendars: {}, event: {} });
    import {
    createCalendar,
    viewMonthAgenda,
    viewMonthGrid,
    viewWeek
    } from '@schedule-x/calendar'
    import '@schedule-x/theme-default/dist/index.css'
    import {createEventRecurrencePlugin, createEventsServicePlugin} from "@schedule-x/event-recurrence";
    import { CalendarEvent } from '@schedule-x/calendar'
    import { createResizePlugin } from '@schedule-x/resize'
    import {createEventModalPlugin} from "@schedule-x/event-modal";
    import {createDragAndDropPlugin} from "@schedule-x/drag-and-drop";
    import {createScrollControllerPlugin} from "@schedule-x/scroll-controller";
    import { createSidebarPlugin } from "@sx-premium/sidebar";
    import { createInteractiveEventModal } from "@sx-premium/interactive-event-modal";
   
    import '@sx-premium/sidebar/dist/index.css'
    import '@sx-premium/interactive-event-modal/dist/index.css'

     import { ref, watchEffect } from "vue"
     export default {
       name: 'My Estimate calendar',
       props: ['prop'],
       emits: ['onAction'],
        components: {
          Multiselect,
          iServiceSwitch,
          Toggle,
          VariableAndStockResponses,
          MyEstimateEmailReminderPreview,
          EstimateQuotePreview,
          EmailVisitNotice,
          ScheduleXCalendar,
            createCalendar,
            viewMonthAgenda,
            viewMonthGrid,
            viewWeek,
            createEventRecurrencePlugin, createEventsServicePlugin,
            //CalendarEvent,
            createResizePlugin,
            createEventModalPlugin,
            createDragAndDropPlugin,
            createScrollControllerPlugin,
            createSidebarPlugin,
            createInteractiveEventModal,
            CalendarMessageTableList
            
       },
       data: function () {
         return {
            allowmultiple : false,
            customerviewable : false,
            schedule : true,
            reminder: false,
            completeInspection: false,
            sendQuote  : false,
            approval : false,
            startJob:false,
            step : 'schedule',
            assignestimate : false,
            rejectMessage : false,
            acceptMessage : false,
            estimatetab : true,
            jobtab : false,
            rejectMessage1 : false,
            acceptMessage1 : false,
            showCalendar : true

         }
       },
       events: {
        
       },
       mounted() { 
            
        },
       methods: {
         showStep(stepdata){
            this.step = stepdata;
         },
         showModal() {
            document.getElementById("event-details-popup").click(); 
        },
        toggleCalendar(){
            this.showCalendar = !this.showCalendar;
        }
       },
       setup(props,context){
        
        const eventsService = createEventsServicePlugin();
        const interactiveEventModal = createInteractiveEventModal({
        eventsService,
        availablePeople: ['John Doe', 'Jane Doe'],
        onAddEvent: (event) => {
            console.log('Event added', event)
        }
        });
        const sidebar = createSidebarPlugin({
            eventsService,
            
            // Optional: Should the sidebar be open on render
            openOnRender: false,
            
            // Optional: Which calendars should be active on render
            activeCalendarIds: ['personal', 'work', 'leisure', 'school'],
            
            // Optional: Should there be calendar toggles
            hasCalendarToggles: true,
            
            // Optional: placeholder events for drag-to-create
            placeholderEvents: [
                {
                title: 'Morning brief',
                calendarId: 'internal',
                people: ['John Doe', 'Jane Doe', 'Steve Smith'],
                },
                {
                title: 'Client demo',
                calendarId: 'internal',
                people: ['John Doe', 'Jane Doe'],
                },
                {
                title: 'Team meeting',
                calendarId: 'clients',
                people: ['John Doe', 'Jane Doe', 'Steve Smith'],
                }
            ],
            
            // Optional: factory function for generating event ids when events are created
            idFactory: () => uuidv4() // or any other id generator
            })
        
        let isSelecting = false;
        let selectionStart = null;
        let selectionEnd = null;
        let selectionOverlay = null;
        Vue.watchEffect(() => {
            document.addEventListener('mousedown', function (event) {
            if (event.target && event.target.classList && event.target.classList.length && event.target.classList[0] == 'sx__time-grid-day') {
                selectionStart = null;
                selectionEnd = null;
                isSelecting = true;
                selectionStart = getDateAtEvent(event);
                // Create and style the selection overlay
                selectionOverlay = document.createElement('div');
                selectionOverlay.classList.add('selection-overlay');
                
                // Initialize the overlay position and size
                updateOverlay(event);
            }
            });
            document.addEventListener('mousemove', function (event) {
            if (isSelecting) {
                if ((event.offsetY % 15) > 0) return;
                let selectElement = event.target?.getElementsByClassName('overlay-start-time')
                if (selectElement.length == 0) event.target.appendChild(selectionOverlay);

                let startTime = event.target?.getElementsByClassName('overlay-start-time');
                if (startTime.length > 0) {
                startTime[0].innerHTML = selectionStart.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                } else {
                startTime = document.createElement('span');
                startTime.classList.add('overlay-start-time');
                startTime.innerHTML = selectionStart.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                selectionOverlay.appendChild(startTime);
                }

                selectionEnd = getDateAtEvent(event);
                let start = null;
                let end = null;
                startTime = event.target?.getElementsByClassName('overlay-start-time');
                start = new Date(selectionStart.date);
                start.setHours(0, 0, 0, 0);
                end = new Date(selectionEnd.date);
                end.setHours(0, 0, 0, 0);
                if (selectionStart.clientY < event.offsetY) {
                start.setMinutes(selectionStart.clientY);
                end.setMinutes(event.offsetY);
                } else {
                start.setMinutes(event.offsetY);
                end.setMinutes(selectionStart.clientY);
                }
                selectionStart.date = start;
                selectionEnd.date = end;
                startTime[0].innerHTML = start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                let endTime = document.getElementsByClassName('overlay-end-time');
                if (endTime.length > 0) {
                endTime[0].innerHTML = end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                } else {
                endTime = document.createElement('span');
                endTime.classList.add('overlay-end-time');
                endTime.innerHTML = selectionEnd.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                selectionOverlay.appendChild(endTime);
                }
                updateOverlay(event); // Update overlay position and size
            }
            });
            document.addEventListener('mouseup', function (event) {
            if (isSelecting) {
                isSelecting = false;
                if (!selectionEnd) return;
                let start = null;
                let end = null;
                start = new Date(selectionStart.date);
                start.setHours(0, 0, 0, 0);
                end = new Date(selectionEnd.date);
                end.setHours(0, 0, 0, 0);
                if (selectionStart.clientY < selectionEnd.clientY) {
                start.setMinutes(selectionStart.clientY);
                end.setMinutes(selectionEnd.clientY);
                } else {
                start.setMinutes(selectionEnd.clientY);
                end.setMinutes(selectionStart.clientY);
                }
                selectionStart.date = start;
                selectionEnd.date = end;
                if (selectionStart && selectionEnd) {
                feature.title = "";
                if (props.config?.startProperty && allValues[props.config?.startProperty]) {
                    allValues[props.config?.startProperty].Details([{ valueDatetimeUtc: iservice.date.apiDatetimeOut(selectionStart.date) }]);
                }
                if (props.config?.endProperty && allValues[props.config?.endProperty]) {
                    allValues[props.config?.endProperty].Details([{ valueDatetimeUtc: iservice.date.apiDatetimeOut(selectionEnd.date) }]);
                }
                feature.isCreateEventForm = true;
                }
                document.getElementById("event-details-popup").click(); 
            }
            });
            function updateOverlay(event) {
             
            const startY = Math.min(event.offsetY, selectionStart.clientY);
            const endY = Math.max(event.offsetY, selectionStart.clientY);

            //selectionOverlay.style.left = `${startX}px`;
            selectionOverlay.style.top = `${startY}px`;
            selectionOverlay.style.width = `100%`;
            selectionOverlay.style.height = `${endY - startY}px`;
            }
            function getDateAtEvent(event) {
            let eventY = (parseInt(JSON.parse(JSON.stringify(event.offsetY)) / 15)) * 15;
            
            const date = new Date(iservice.date.apiDateIn(event.target.dataset.timeGridDate).setHours(0, 0, 0, 0));
          
            //date.setMinutes(eventY);
            // Include client coordinates in the returned object for overlay positioning
            return {
                date: date,
                clientX: event.offsetX,
                clientY: eventY
            };
            }
        }); 
        const calendarApp = createCalendar({
        selectedDate: '2024-08-22',
        locale: 'en-UK',
        views: [viewMonthAgenda, viewMonthGrid, viewWeek],
        defaultView: viewWeek.name,
        plugins: [
            createEventModalPlugin(),
            createDragAndDropPlugin(30),
            createScrollControllerPlugin(),
            createEventRecurrencePlugin(),
            createResizePlugin(),
            eventsService,
            sidebar,
            interactiveEventModal,
            
        ],
        callbacks: {
            onRangeUpdate(range) {
        console.log('new calendar range start date', range.start)
        console.log('new calendar range end date', range.end)
        },
    
        /**
         * Is called when an event is updated through drag and drop
         * */
        onEventUpdate(updatedEvent) {
        console.log('onEventUpdate', updatedEvent)
        },
    
        /**
        * Is called when an event is clicked
        * */
        onEventClick(calendarEvent) {
        console.log('onEventClick', calendarEvent)
        },
    
        /**
        * Is called when clicking a date in the month grid
        * */
        onClickDate(date) {
        console.log('onClickDate', date) // e.g. 2024-01-01
        },
    
        /**
        * Is called when clicking somewhere in the time grid of a week or day view
        * */
        onClickDateTime(dateTime) {
        console.log('onClickDateTime', dateTime) // e.g. 2024-01-01 12:37
        },
    
        /**
        * Is called when selecting a day in the month agenda
        * */
        onClickAgendaDate(date) {
        console.log('onClickAgendaDate', date) // e.g. 2024-01-01
        },
    
        /**
        * Is called when double clicking a date in the month grid
        * */
        onDoubleClickDate(date) {
        console.log('onClickDate', date) // e.g. 2024-01-01
        },
    
        /**
        * Is called when double clicking somewhere in the time grid of a week or day view
        * */
        onDoubleClickDateTime(dateTime) {
        console.log('onDoubleClickDateTime', dateTime) // e.g. 2024-01-01 12:37
        },
    
        /**
        * Is called when clicking the "+ N events" button of a month grid-day
        * */
        onClickPlusEvents(date) {
        console.log('onClickPlusEvents', date) // e.g. 2024-01-01
        },
    
        /**
        * Is called when the selected date is updated
        * */
        onSelectedDateUpdate(date) {
        console.log('onSelectedDateUpdate', date)
        },
    
            },
        events: [
            {
            id: 1,
            title: 'Estimate request',
            start: '2024-08-22',
            end: '2024-08-22',
            }
        
        ],
        calendars: {
            leisure: {
            colorName: 'leisure',
            lightColors: {
                main: '#1c7df9',
                container: '#d2e7ff',
                onContainer: '#002859',
            },
            darkColors: {
                main: '#c0dfff',
                onContainer: '#dee6ff',
                container: '#426aa2',
            },
            },
            },
                monthGridOptions: {
                    nEventsPerDay: 3,
                }
                })
                return{calendarApp};
        
        },  

       
        
     }
        
 </script>