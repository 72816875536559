<template>
    <div class="row">
      <div class="message-box-header">
          <div class="col-md-12">
              <div class="subject-heading">
                  <b>Subject : </b> 	Quote request for Jacob Smith -​ Round Rock
              </div>
          </div> 
          <div class="col-md-12">
              <div class="user-container">
                  <span class="user">
                      <img src="/images/customer.png">
                      <b>Customer</b>
                  </span>
                  <span>Jacob Smith</span>
              </div>  
              <div class="message-info">
                  <b>From : </b>jacob.smith@example.com<br>
                  <b>To : </b>Insulation Contracting / Homeowner Estimates<br>
              </div>	
              <div class="int-det-box pull-right"> 
                  <div><b>Interaction ID : </b>4645646</div>
                  <div><b> Segment / Topic : </b>Insulation Contracting / Homeowner Estimates</div>
                  <div><b> Resolved By : </b></div>
                  <div><b> Approved By : </b>&nbsp;</div><div><b> Assigned To : </b>&nbsp;</div>		
                  <div><b> Interaction Status : </b><small class="bg-red-disabled label">Queued</small></div>									
                  <div><b> Interaction Type : </b><small class="label label-with-icon bg-blue"><i class="fa fa-envelope"></i>Customer Email</small><span class="email-download" v-tooltip="{text: 'Download Customer Email',theme: {placement: 'left', }}" ><i class="fa fa-download"></i></span></div>
              </div>
          </div>
          <div class="clearfix"></div>
         </div>
      </div>
  </template>
  <script>
    export default {
    name: 'DetailsandattachmentsTabContentComponents',
     components: {
  
    },
    data: function(){
       
    }
  
  }
  
  </script>