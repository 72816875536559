<template>
    <div class="notdatatable form-inline mbottom-15">
       <div class="table-controls table-columns">
          <div class="btn-group"><button class="btn btn-default" type="button"><span>Excel</span></button><button class="btn btn-default button-columns" type="button"><span>Column visibility</span></button></div>
       </div>
       <div class="table-controls table-pagesize table-columns">
          <label>
             Show 
             <select class="form-control input-xs">
                <option>5</option>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>250</option>
                <option>500</option>
                <option>1000</option>
             </select>
             entries 
          </label>
       </div>
       <div class="table-controls table-filter">Filter Messages <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
    </div>
    <table class="table table-striped">
       <thead>
          <tr role="row">
            <th class="sorting sorting_asc">
                Subject 
            </th>
            <th class="sorting">
                Contact Name
            </th>
            <th class="sorting">
              Contact Email
            </th>
             <th class="sorting">
                Date
            </th>
            <th class="sorting">
                Assigned To
            </th>
            <th class="sorting">
                Topic
            </th>
          </tr>
       </thead>
        
       <tbody>
         <tr role="row" class="color-normal" v-on:click = " showexpanded1 = !showexpanded1">
            <td>
                 <span>
                    Quote request for Jacob Smith -​ Round Rock
                </span>
            </td>
            <td>
                <a href="">Jacob Smith</a>
            </td>
            <td>
                <span class="fa-stack new-account-icon" v-tooltip="{text: 'Bounced Email',theme: {placement: 'bottom', }}">
                  <i class="fa fa-envelope-o"></i>
                  <i class="fa  fa-exclamation-triangle  icon-plus"></i>
                </span>
               
                <a href="">Jacob.smith@<br>example.com</a>
                
            </td>
            <td class="column-date">
               <p v-tooltip="{text: '2018-08-03 11:59:44 AM',theme: {placement: 'bottom', }}">5 min ago</p>
            </td>
          
            <td>
                <i class="fa icon-c icon-external-agent external-agent" ></i> <span v-tooltip="{text: 'This interaction is assigned to an external agent',theme: {placement: 'bottom'}}">Justin Time</span>
            </td>
            <td>
               _Feedback
            </td>
            
          </tr>
            <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded1">
                <td colspan="6">
                    <CalendarViewModeContent v-if="CalendarViewModeContent" showGetThis = "1" showPaymentStatus = "0"></CalendarViewModeContent>
    
                    <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>
    
                    <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>
    
                    <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>
    
                    <ForwardContent v-if="forwardccopyontent"></ForwardContent>
    
                    <GetThisContent v-if="getthiscontent"></GetThisContent> 
                </td> 
            </tr>
            <tr role="row" class="color-warning"  v-on:click = " showexpanded2 = !showexpanded2">
                  
                    <td>
                 <span>
                    Quote request for Jacob Smith
                </span>
            </td>
             <td>
                <a href="">Jacob Smith</a>
            </td>
            <td>
               <a href=""> Jacob.Smith@example.com</a>
            </td>
            <td class="column-date">
               <p v-tooltip="{text: '2018-08-03 11:59:44 AM',theme: {placement: 'bottom', }}">5 min ago</p>
            </td>
          
            <td>
                Jonie Hewlett
            </td>
             
            <td>
               _Feedback
            </td>   
            </tr>
            <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded2">
                <td colspan="6">
                    <CalendarViewModeContent v-if="CalendarViewModeContent" showGetThis = "1" showPaymentStatus = "0"></CalendarViewModeContent>
    
                    <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>
    
                    <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>
    
                    <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>
    
                    <ForwardContent v-if="forwardccopyontent"></ForwardContent>
    
                    <GetThisContent v-if="getthiscontent"></GetThisContent> 
                </td> 
            </tr>
            <tr role="row" class="color-urgent" v-on:click = " showexpanded3 = !showexpanded3">
                    <td>
                    <span>
                        Quote request for James Williams
                    </span>
                    </td>
                    <td>
                        <a href="">James Williams</a>
                    </td>
                     <td>
                        <a href="">James.Williams@example.com</a>
                    </td>
                    <td class="column-date">
                      <p v-tooltip="{text: '2018-08-03 11:59:44 AM',theme: {placement: 'bottom', }}">5 min ago</p>
                    </td>
                
                    <td>
                       Jonie Hewlett
                    </td>
                    
                    <td>
                    _Feedback
                    </td>
            </tr>
            <tr class="expandedrowcontent  bg-agent-reply" v-bind:class="replyType" v-if="showexpanded3">
                <td colspan="6">
                    <CalendarViewModeContent v-if="CalendarViewModeContent" showGetThis = "1" showAnswer = '0' showPaymentStatus = "1"></CalendarViewModeContent>
    
                    <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>
    
                    <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>
    
                    <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>
    
                    <ForwardContent v-if="forwardccopyontent"></ForwardContent>
    
                    <GetThisContent v-if="getthiscontent"></GetThisContent>
                </td> 
            </tr>
       </tbody>
    </table>
    <div id="Main_MyDashboard_Recent_TableInfo">
       <div class="notdatatable table-info">Showing 1 to 5 of 29 entries</div>
       <div class="dataTables_paginate notdatatable table-pagination text-end">
          <ul class="pagination">
             <li><a href="">Previous</a></li>
             <li class="active"><a href="">1</a></li>
    
             <li><a href="">Next</a></li>
          </ul>
       </div>
    </div>
    </template>
    
    <script>
    import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
    import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
    
    import CalendarViewModeContent from '../components/CalendarViewModeContent.vue';
    import CreateNoteContent from '../components/CreateNoteContent.vue';
    import AgentEmailContent from '../components/AgentEmailContent.vue';
    import CreateTicketContent from '../components/CreateTicketContent.vue';
    import ForwardContent from '../components/ForwardContent.vue';
    import GetThisContent from '../components/GetThisContent.vue';
    import PaymentContent from '../components/PaymentContent.vue';
    import CaseProperty from '../components/CaseProperty.vue';
    import StatusButton from '../components/StatusButton.vue';
    
    
    import Multiselect from '@vueform/multiselect'
    
        export default {
          name: 'Calendar Message List',
          props: {replyType:String,showGetThis: Boolean, statusBtnText:String, statusBtnClass:String, showstatusBtnIcon:Boolean,statusBtnIconClass:String, typeBtnText:String, typeBtnClass:String, showtypeBtnIcon:Boolean, typeBtnIconClass:String,typeBtnIconClassGroup:String,typeGroupIcon:Boolean},
          components: {
            Multiselect,
            InteractionPropertyTab,
            DetailsandattachmentsTabContent,
            CalendarViewModeContent,
            CreateNoteContent,
            AgentEmailContent,
            CreateTicketContent,
            ForwardContent,
            GetThisContent,
            CaseProperty,
            StatusButton,
            GetThisContent,
            PaymentContent
          },
          data: function(){
              return{
                showBCC : true,
                date: new Date(),
                timezone: '',
                showexpanded1: false,
                CalendarViewModeContent : true,
                createnotecontent : false,
                agentemailcontent : false,
                createticketcontent : false,
                forwardcontent  : false,
                getthiscontent : false,
                paymentcontent : false,
                replyType : this.replyType,
                showGetThis : this.showGetThis,
                statusbtntext:this.statusBtnText, 
                statusbtnclass:this.statusBtnClass, 
                showstatusbtnicon:this.showstatusBtnIcon,
                statusbtniconclass:this.statusBtnIconClass, 
                typebtntext:this.typeBtnText, 
                typebtnclass:this.typeBtnClass,
                showtypebtnicon:this.showtypeBtnIcon, 
                typebtniconclass:this.typeBtnIconClass,
                typebtniconclassgroup: this.typeBtnIconClassGroup,
                typegroupicon : this.typeGroupIcon,
                showexpanded2 : false,
                showexpanded3 : false
              }
          },
          mounted(){
               
               this.emitter.on("show-view-details", Data => {
                  this[Data.displaySegment] = true;
                  this.CalendarViewModeContent = false;
                });
                this.emitter.on("hide-view-details", Data => {
                  this[Data.displaySegment] = false;
                  this.CalendarViewModeContent = true;
                });
          },
            methods: {
                toggleSegmentCollapsedState(i) {
                this['segment'+i] = !this['segment'+i] 
                }
            }
        }
    </script>