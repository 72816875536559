<template>
    <Breadcrumb page-title="Estimates Calendar" page-section="Estimates Calendar"> </Breadcrumb>
      <section class="content">
            <MyEstimateCalendar></MyEstimateCalendar>
      </section>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Toggle from '@vueform/toggle'
import iServiceSwitch from '../components/iServiceSwitch.vue';
import MyEstimateCalendar from '../components/MyEstimateCalendar.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'

export default {
  name: 'My Estimates',
   components: {
    Breadcrumb,
    iServiceSwitch,
    Multiselect,
    MyEstimateCalendar,
    Toggle,
    Multiselect,
  
  },
  setup(){      
       
   
       var showDetails = reactive({showNew:false,deleteAlert:false, myEstimatesDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';

       function showNewAlert(){
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.myEstimatesDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.myEstimatesDetails=true;
         showDetails.active_el = row;
         showDetails.activeprop = val;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.myEstimatesDetails=false;
       }

       return{cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>